import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Fragment } from 'react';

import ReactGA from 'react-ga';

import './App.css';

// Components
import ScrollTop from './components/scrollTop/ScrollTop';
import Landing from './components/Landing/Landing';
import Experience from './components/experience/Experience';
import PageView from './components/pageViews/PageViews';

const TRACKING_ID = 'UA-150911020-1';
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview('/');

const App = () => {
  return (
    <Router>
      <PageView />
      <ScrollTop>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route
            path="/alldid"
            component={() => (
              <Experience
                company="Alldid"
                role="Co-founder, CTO"
                logo="/assets/images/alldid.webp"
                site="https://www.alldid.com"
                technologies="React, Redux, Node.js, Express, MongoDB, Cloudinary, Passport"
                companyDescription={
                  <Fragment>
                    <h3>Company Information</h3>
                    <p>
                      Alldid is a platform that connects hair professionals to
                      clients like never before. Hair professionals can
                      specialize in services they excel at, and along the way
                      accumulate endorsements. Clients can then match with the
                      top specialists in there service to improve service
                      quality and client retention.
                    </p>
                  </Fragment>
                }
                roleDescription={
                  <Fragment>
                    <h3>Role Description</h3>
                    <ul>
                      <li>
                        Involved in the conception and development of the UI/UX
                        for the MVP product
                      </li>
                      <li>
                        Provided my technical website experience to make
                        critical platform decisions that shaped the platform
                        experience
                      </li>
                      <li>
                        Developed the MVP platform with a scalable back-end
                        utilizing Node.js and MongoDB for reliable web
                        communications
                      </li>
                      <li>
                        Crafted the front-end user interface using React to
                        allow for a seamless user experience and increased
                        engagement
                      </li>
                    </ul>
                  </Fragment>
                }
                screenshots={[
                  {
                    desktop: '/assets/images/Alldid/Landing.webp',
                    mobile: '/assets/images/Alldid/Landing-Mobile.webp',
                  },
                  {
                    desktop: '/assets/images/Alldid/Matches.webp',
                  },
                  {
                    desktop: '/assets/images/Alldid/Profile.webp',
                  },
                  {
                    mobile: [
                      '/assets/images/Alldid/Matches-Mobile.webp',
                      '/assets/images/Alldid/Profile-Mobile.webp',
                    ],
                  },
                ]}
              />
            )}
          />
          <Route
            path="/jamesrudman"
            component={() => (
              <Experience
                company="James Rudman"
                role="Front-end Developer"
                logo="/assets/images/JR-white.webp"
                site="http://jamesrudman.com"
                technologies="HTML, CSS, JavaScript"
                companyDescription={
                  <Fragment>
                    <h3>Client Information</h3>
                    <p>
                      James Rudman is a videographer/photographer who has been
                      perfecting his craft since he was in highschool. He has
                      had the opportunity to work with many different
                      organizations and brands over his years as an independent
                      content creator. His website is a portfolio to display
                      this content in a streamlined way.
                    </p>
                  </Fragment>
                }
                roleDescription={
                  <Fragment>
                    <h3>Role Description</h3>
                    <ul>
                      <li>
                        Utilized my effective communication skills to design a
                        responsive user interface for a videography portfolio
                        alongside the client
                      </li>
                      <li>
                        Implemented a static site using vanilla JavaScript,
                        delivering the necessary content efficiently without the
                        extra hosting costs to match the client’s constraints
                      </li>
                    </ul>
                  </Fragment>
                }
                screenshots={[
                  {
                    desktop: '/assets/images/JR/Landing.webp',
                  },
                  {
                    desktop: '/assets/images/JR/Videos.webp',
                  },
                  {
                    mobile: [
                      '/assets/images/JR/Landing-Mobile.webp',
                      '/assets/images/JR/Videos-Mobile.webp',
                    ],
                  },
                ]}
              />
            )}
          />
          <Route
            path="/11thStreet"
            component={() => (
              <Experience
                company="Eleventh Street Worldwide"
                role="Full-stack Developer"
                logo="/assets/images/11thStreetLogo.webp"
                site="https://www.eswcu.com"
                technologies="Node.js, Express, MongoDB, Stripe, HTML, CSS, JavaScript, Pug/Jade"
                companyDescription={
                  <Fragment>
                    <h3>Client Information</h3>
                    <p>
                      Eleventh Street Worldwide is a media collective that helps
                      their members grow as part of the collective. Their
                      members span all different kinds of content creation
                      including videographers, photographers, artists,
                      song-writers, and DJs.
                    </p>
                  </Fragment>
                }
                roleDescription={
                  <Fragment>
                    <h3>Role Description</h3>
                    <ul>
                      <li>
                        Implemented a front-end interface to increase user
                        engagement with the Eleventh Street media collective’s
                        content
                      </li>
                      <li>
                        Designed and developed a back-end rendering system using
                        Node.js, MongoDB and the pug templating agent to deliver
                        dynamic content to the target audience
                      </li>
                      <li>
                        Implemented a merchandise shop using the Stripe payment
                        SDK allowing for swift transactions
                      </li>
                    </ul>
                  </Fragment>
                }
              />
            )}
          />
        </Switch>
      </ScrollTop>
    </Router>
  );
};

export default App;
