import { useState, useRef, useEffect } from 'react';
import { FadeIn } from 'react-anim-kit';

import Card from './Card/Card';

import classes from './Work.module.css';

const Work = () => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let curr = ref.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entries[0].isIntersecting) {
          setVisible(true);

          if (ref !== null && ref.current !== null) {
            observer.unobserve(ref.current);
          }
        }
      });
    });
    if (ref !== null && ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => {
      if (curr !== null) observer.unobserve(curr);
    };
  }, []);

  return (
    <section id="work" className={classes.Work}>
      <div className={classes.Content} ref={ref}>
        {isVisible && (
          <FadeIn up by={10} delayBy={0.25}>
            <h2>Work</h2>
          </FadeIn>
        )}
        {isVisible && (
          <FadeIn up by={10} delayBy={0.25} className={classes.WorkGrid}>
            <Card
              label="Alldid"
              imgSrc="/assets/images/alldid.webp"
              link="/alldid"
              position="Co-Founder, CTO"
            />
            <Card
              label="James Rudman"
              imgSrc="/assets/images/JR.webp"
              link="/jamesrudman"
              position="Front-end Developer"
            />
            <Card
              label="Eleventh Street"
              imgSrc="/assets/images/11thStreet.webp"
              link="/11thStreet"
              position="Full-stack Developer"
            />
          </FadeIn>
        )}
      </div>
    </section>
  );
};

export default Work;
