import React from 'react';
import PropTypes from 'prop-types';
import classes from './TextAreaGroup.module.css';

type Props = {
  name: string;
  className: string;
  innerClassName: string;
  value: string;
  label: string;
  placeholder: string;
  rows: number;
  cols: number;
  maxlength: number;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  error: string;
  info: string;
};

const TextAreaGroup = (props: Props) => {
  return (
    <div className={`${classes.TextAreaGroup} ${props.className}`}>
      {props.label !== '' && <label htmlFor={props.name}>{props.label}</label>}
      <textarea
        className={props.innerClassName}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        rows={props.rows}
        cols={props.cols}
        maxLength={props.maxlength}
      />
      {props.info && <small className="form-text">{props.info}</small>}
      {props.error && (
        <div className="error invalid-feedback" style={{ marginLeft: '28px' }}>
          {props.error}
        </div>
      )}
    </div>
  );
};

TextAreaGroup.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  cols: PropTypes.number.isRequired,
  maxlength: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

TextAreaGroup.defaultProps = {
  name: '',
  className: '',
  innerClassName: '',
  value: '',
  label: '',
  placeholder: '',
  rows: 4,
  cols: 20,
  maxlength: 300,
  onChange: null,
  error: '',
  info: '',
};

export default TextAreaGroup;
