import classes from './Experience.module.css';

interface DesktopUI {
  desktop: string;
  mobile?: string;
}

interface MobileUI {
  mobile: string[];
}

interface UIScreenshot {}

type Props = {
  company: string;
  role: string;
  logo: string;
  site: string;
  technologies: string;
  companyDescription: JSX.Element;
  roleDescription: JSX.Element;
  screenshots?: (DesktopUI | MobileUI)[];
};

const Experience = ({
  company,
  role,
  logo,
  site,
  technologies,
  companyDescription,
  roleDescription,
  screenshots,
}: Props) => {
  return (
    <main className={classes.Experience}>
      <a className={classes.Back} href="/#work">
        <img src="/assets/icons/back.webp" alt="back" />
      </a>
      <div className={classes.Header}>
        <img src={logo} alt={`${company} logo`} />
        <h1>{company}</h1>
        <h2>{role}</h2>
        <a href={site} target="_blank" rel="noreferrer">
          View Site
        </a>
        <p className={classes.Tech}>
          <b>Technologies: </b>
          {technologies}
        </p>
      </div>
      <div className={classes.Company}>{companyDescription}</div>
      <div className={classes.Role}>{roleDescription}</div>
      {screenshots && (
        <div className={classes.UI}>
          {screenshots.map((e) => {
            if ('desktop' in e) {
              return (
                <div className={classes.Screenshot}>
                  {e.desktop && (
                    <img
                      className={classes.Desktop}
                      src={e.desktop}
                      alt="Desktop UI"
                    />
                  )}
                  {e.mobile && (
                    <img
                      className={classes.Mobile}
                      src={e.mobile}
                      alt="Mobile UI"
                    />
                  )}
                </div>
              );
            } else {
              return (
                <div className={classes.Grid}>
                  {Array.isArray(e.mobile) &&
                    e.mobile.map((item) => {
                      return (
                        <img
                          className={classes.Item}
                          src={item}
                          alt="Mobile UI"
                        />
                      );
                    })}
                </div>
              );
            }
          })}
        </div>
      )}
    </main>
  );
};

export default Experience;
