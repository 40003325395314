import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FadeIn } from 'react-anim-kit';
import classes from './Card.module.css';

type Props = {
  label: string;
  imgSrc: string;
  link: string;
  position: string;
};

const Card = ({ label, imgSrc, link, position }: Props) => {
  const [visible, setVisible] = useState(false);

  const handleMouseEnter = () => {
    setVisible(true);
  };
  const handleMouseLeave = () => {
    setVisible(false);
  };

  let styles: React.CSSProperties = {};

  if (!visible) {
    styles.opacity = '0';
  } else {
    styles.opacity = '1';
  }

  return (
    <Link
      className={classes.Card}
      to={link}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={imgSrc} alt={`${label} Logo`} />
      <div className={classes.Overlay} style={styles}>
        {visible && (
          <FadeIn up by={10} className={classes.Position}>
            <p>{position}</p>
          </FadeIn>
        )}
        {visible && (
          <FadeIn up by={10} className={classes.ViewMore}>
            <p>View Role</p>
          </FadeIn>
        )}
      </div>
    </Link>
  );
};

export default Card;
