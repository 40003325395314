import { useState, useRef, useEffect } from 'react';
import { FadeIn } from 'react-anim-kit';

import classes from './About.module.css';

const waveSrc =
  "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1007%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c188 C 96%2c249.6 288%2c492.4 480%2c496 C 672%2c499.6 768%2c229.6 960%2c206 C 1152%2c182.4 1344%2c343.6 1440%2c378L1440 560L0 560z' fill='%232264ab'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1007'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e";

const About = () => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let curr = ref.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entries[0].isIntersecting) {
          setVisible(true);

          if (ref !== null && ref.current !== null) {
            observer.unobserve(ref.current);
          }
        }
      });
    });
    if (ref !== null && ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => {
      if (curr !== null) observer.unobserve(curr);
    };
  }, []);

  return (
    <section className={classes.About}>
      <img className={classes.Wave} src={waveSrc} alt="wave" />
      <div className={classes.Content} ref={ref}>
        {isVisible && (
          <FadeIn up by={10} delayBy={0.25}>
            <h2>About</h2>
          </FadeIn>
        )}
        {isVisible && (
          <FadeIn up by={10} delayBy={0.25} className={classes.AboutRow}>
            <div className={classes.Info}>
              <p>
                My name is Ryan, I graduated in May 2021 from Syracuse
                University with a Bachelor's degree in Computer Engineering.
                Some of my hobbies include playing sports, videography, and
                photography. In my free time, I mostly enjoy designing and
                building fast and reliable web applications.
              </p>
              <p>
                I enjoy the opportunity to utilize not only my engineering
                skills, but also my excellent communication ability to take the
                client's vision and turn it into reality. With all my clients we
                begin by discussing their needs, mock up a design they are
                satisfied with, and then create a finished product that they can
                be proud of.
              </p>
              <p>
                I spend everyday learning and improving my craft so that I can
                provide a better service for those in need of utilizing the
                increasing possibilities of the modern day web. If you are
                interested in having a chat about your web presence needs, you
                can contact me here.
              </p>
            </div>
            <div className={classes.Image}>
              <img src="/assets/images/me-cropped.webp" alt="Me" />
            </div>
          </FadeIn>
        )}
      </div>
    </section>
  );
};

export default About;
