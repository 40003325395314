import React, { useState, useRef, useEffect } from 'react';
import { FadeIn } from 'react-anim-kit';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import TextFieldGroup from '../../common/TextFieldGroup';
import TextAreaGroup from '../../common/TextAreaGroup/TextAreaGroup';
import Loader from '../../common/Loader/Loader';

import classes from './Contact.module.css';

const Contact = () => {
  const [isVisible, setVisible] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [captcha, setCaptcha] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const captchaRef = useRef<any>(null);

  useEffect(() => {
    let curr = ref.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entries[0].isIntersecting) {
          setVisible(true);

          if (ref !== null && ref.current !== null) {
            observer.unobserve(ref.current);
          }
        }
      });
    });
    if (ref !== null && ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => {
      if (curr !== null) observer.unobserve(curr);
    };
  }, []);

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);
    setErrors({});
    let errors: any = {};
    if (name === '') errors.name = 'Name required';
    if (email === '') errors.email = 'Email required';
    if (subject === '') errors.subject = 'Subject required';
    if (body === '') errors.body = 'Body required';
    if (captcha === null) {
      window.alert('Captcha required');
      return;
    }
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }

    setSubmitting(true);

    // axios post
    axios
      .post('/api/main/contact', {
        name,
        email,
        subject,
        body,
        captcha,
      })
      .then((res) => {
        if (res.data.success) {
          // Success
          setSuccess(true);
          setError(false);
          setSubmitting(false);
          if (captchaRef !== null && captchaRef.current !== null) {
            captchaRef.current.reset();
          }
        } else {
          // Failed
          setError(true);
          setSuccess(false);
          setSubmitting(false);
          if (captchaRef !== null && captchaRef.current !== null) {
            captchaRef.current.reset();
          }
        }
      });
  };

  const handleReset = () => {
    if (captchaRef !== null && captchaRef.current !== null) {
      captchaRef.current.reset();
    }
  };

  return (
    <section id="Contact" className={classes.Contact}>
      <div className={classes.Content} ref={ref}>
        {isVisible && (
          <FadeIn up by={10} delayBy={0.25}>
            <h2>Contact</h2>
          </FadeIn>
        )}
        {isVisible && (
          <FadeIn up by={10} delayBy={0.25} className={classes.FormContainer}>
            <form onSubmit={onSubmit} className={classes.ContactForm}>
              <div className={classes.FormStatus}>
                {error && (
                  <div className={classes.FormErr}>
                    An unexpected error occured! Try again.
                  </div>
                )}
                {success && (
                  <div className={classes.FormSuccess}>
                    Success! I will get back to you shortly.
                  </div>
                )}
              </div>
              <div className={classes.ContactRow}>
                <div className={classes.ContactGroup}>
                  <label htmlFor="name">Name</label>
                  <TextFieldGroup
                    name="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                    placeholder="Name"
                    autoComplete="name"
                    wrapperClassName={classes.TextInputWrapper}
                    inputClassName={classes.TextInput}
                    error={errors.name}
                  />
                </div>
                <div className={classes.ContactGroup}>
                  <label htmlFor="email">Email</label>
                  <TextFieldGroup
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                    placeholder="Email"
                    autoComplete="email"
                    wrapperClassName={classes.TextInputWrapper}
                    inputClassName={classes.TextInput}
                    error={errors.email}
                  />
                </div>
              </div>
              <div className={classes.ContactGroup}>
                <label htmlFor="subject">Subject</label>
                <TextFieldGroup
                  name="subject"
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.currentTarget.value)}
                  placeholder="Subject"
                  wrapperClassName={classes.TextInputWrapper}
                  inputClassName={classes.TextInput}
                  error={errors.subject}
                />
              </div>
              <div className={classes.ContactGroup}>
                <label htmlFor="body">Body</label>
                <TextAreaGroup
                  name="body"
                  value={body}
                  onChange={(e) => setBody(e.currentTarget.value)}
                  placeholder="Message"
                  maxlength={1000}
                  error={errors.body}
                />
              </div>
              <div className={classes.Captcha}>
                <ReCAPTCHA
                  sitekey="6LdJ8OMeAAAAAGXLrTNwrMViJhNwMusDXCKV-AsL"
                  onChange={(val) => setCaptcha(val)}
                  onExpired={handleReset}
                  ref={captchaRef}
                />
              </div>
              {!submitting && (
                <input type="submit" className={classes.Submit} />
              )}
              {submitting && (
                <div className={classes.LoaderContainer}>
                  <Loader width="64px" height="64px" color="#2264ab" />
                </div>
              )}
            </form>
          </FadeIn>
        )}
      </div>
    </section>
  );
};

export default Contact;
