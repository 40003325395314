import { useState, useRef, useEffect } from 'react';
import { FadeIn } from 'react-anim-kit';
import Skill from './Skill/Skill';

import classes from './Skills.module.css';
const Skills = () => {
  const [isVisible, setVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let curr = ref.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entries[0].isIntersecting) {
          setVisible(true);

          if (ref !== null && ref.current !== null) {
            observer.unobserve(ref.current);
          }
        }
      });
    });
    if (ref !== null && ref.current !== null) {
      observer.observe(ref.current);
    }

    return () => {
      if (curr !== null) observer.unobserve(curr);
    };
  }, []);

  return (
    <section className={classes.Skills}>
      <div className={classes.Content} ref={ref}>
        {isVisible && (
          <FadeIn up by={10} delayBy={0.25}>
            <h2>Skills</h2>
          </FadeIn>
        )}
        {isVisible && (
          <FadeIn up by={10} delayBy={0.25} className={classes.SkillsCol}>
            <div className={classes.SkillsTitle}>
              <p className={classes.Title}>Skill</p>
              <p className={classes.Value}>Experience</p>
            </div>
            <Skill label="HTML" years={4} />
            <Skill label="CSS" years={4} />
            <Skill label="JavaScript" years={4} />
            <Skill label="React" years={2} />
            <Skill label="Node.js" years={2} />
            <Skill label="Express" years={2} />
            <Skill label="MongoDB" years={2} />
            <Skill label="SQL" years={1} />
          </FadeIn>
        )}
      </div>
    </section>
  );
};

export default Skills;
