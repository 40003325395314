import React from 'react';
import PropTypes from 'prop-types';

type Props = {
  name: string;
  placeholder: string;
  value: string;
  label: string;
  error: string;
  info: string;
  type: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  wrapperClassName: string;
  inputClassName: string;
  autoComplete: string;
};
const TextFieldGroup = (props: Props) => {
  return (
    <div className={`form-group ${props.wrapperClassName}`}>
      <input
        type={props.type}
        className={`form-control ${props.inputClassName}`}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        autoComplete={props.autoComplete}
      />
      {props.info && <small className="form-text">{props.info}</small>}
      {props.error && (
        <div className="error invalid-feedback">{props.error}</div>
      )}
    </div>
  );
};

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.string.isRequired,
};

TextFieldGroup.defaultProps = {
  type: 'text',
  name: '',
  placeholder: '',
  value: '',
  info: '',
  error: '',
  onChange: null,
  label: '',
  autoComplete: 'off',
  wrapperClassName: '',
  inputClassName: '',
};

export default TextFieldGroup;
