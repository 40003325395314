import classes from './Skill.module.css';

type Props = {
  label: string;
  years: number;
};

const Skill = ({ label, years }: Props) => {
  return (
    <div className={classes.Skill}>
      <p className={classes.Title}>{label}</p>
      <p className={classes.Value}>
        {years} year{`${years === 1 ? '' : 's'}`}
      </p>
    </div>
  );
};

export default Skill;
