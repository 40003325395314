import React from 'react';

const classes = require('./Loader.module.css');

type Props = {
  width: string;
  height: string;
  color: string;
};

const Loader = ({ width, height, color }: Props) => {
  return (
    <div className={classes.Loader} style={{ width, height }}>
      <div
        style={{
          border: `0.25rem solid ${color}`,
          borderColor: `${color} transparent transparent transparent`,
        }}
      ></div>
      <div
        style={{
          border: `0.25rem solid ${color}`,
          borderColor: `${color} transparent transparent transparent`,
        }}
      ></div>
      <div
        style={{
          border: `0.25rem solid ${color}`,
          borderColor: `${color} transparent transparent transparent`,
        }}
      ></div>
      <div
        style={{
          border: `0.25rem solid ${color}`,
          borderColor: `${color} transparent transparent transparent`,
        }}
      ></div>
    </div>
  );
};

Loader.defaultProps = {
  width: '100%',
  height: '100%',
  color: '#14586d',
};

export default Loader;
