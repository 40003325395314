import { useState, useEffect } from 'react';
import { FadeIn } from 'react-anim-kit';
import classes from './Landing.module.css';

import About from './About/About';
import Work from './Work/Work';
import Skills from './Skills/Skills';
import Contact from './Contact/Contact';

const waveSrc =
  "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1038%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='%230e2a47'%3e%3c/rect%3e%3cpath d='M 0%2c274 C 57.6%2c279.2 172.8%2c340.2 288%2c300 C 403.2%2c259.8 460.8%2c70.8 576%2c73 C 691.2%2c75.2 748.8%2c285.8 864%2c311 C 979.2%2c336.2 1036.8%2c178.8 1152%2c199 C 1267.2%2c219.2 1382.4%2c369.4 1440%2c412L1440 560L0 560z' fill='%23184a7e'%3e%3c/path%3e%3cpath d='M 0%2c309 C 96%2c253.4 288%2c8 480%2c31 C 672%2c54 768%2c415.2 960%2c424 C 1152%2c432.8 1344%2c144.8 1440%2c75L1440 560L0 560z' fill='%232264ab'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1038'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e";

const Landing = () => {
  // Window Height State
  const height = window.innerHeight;

  return (
    <main className={classes.Landing}>
      <section
        className={classes.LandingSection}
        style={{ height: `${height - 32}px` }}
      >
        <FadeIn up by={20} delayBy={0.25} className={classes.Content}>
          <h1>Ryan Kane</h1>
          <p>Developer. Designer. Freelancer.</p>
        </FadeIn>
        <img className={classes.Wave} src={waveSrc} alt="Wave" />
      </section>
      <About />
      <Work />
      <Skills />
      <Contact />
    </main>
  );
};

export default Landing;
